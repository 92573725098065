@import 'normalize.scss';
@import 'extends.scss';

.theme-button{
    padding: 10px 24px;
    border-radius:$base-border-radius;
    border:none;
    @extend %text-capitalize;
    &.theme-primary-btn {
        background-color:$theme-primary-color;
        color: $white-color;
    }
    &.theme-secondary-btn {
        background-color:#E8F0FE;
        color: $theme-primary-color;
    }
}

.custom-container {
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 0 16px;
  }
}
.white-color {
  @extend %white-color;
}
.theme-secondary-text-color {
  @extend %theme-secondary-text-color;
}
.theme-secondary-color {
  color: $theme-secondary-color;
}
.normal-font {
  @extend %normal-font;
}
.light-font {
  @extend %light-font;
}
.bold-font {
  @extend %bold-font;
}
.medium-bold-font {
  @extend %medium-bold-font
}
.arrow{
  &.swiper-button-disabled{
      opacity: 0.33;
      pointer-events: none;
  }
  img{
      height: 36px;
      width: 36px;
      background-color: $theme-border-color;
      border-radius: 50%;
      padding: 8px;
      opacity: 1;
      &:hover{
        background-color: #fabc2c;
      }
      @media (max-width: $mobile-devices-max-width+px) {
        height: 30px;
        width: 30px;
      }
  }
}
@media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
  .d-none-medium {
    display: none;
  }
}
@media (max-width: $mobile-devices-max-width+px) {
  .d-none-mobile {
    display: none;
  }
  .flex-direction-column-mobile{
    @extend %flex-direction-column-mobile;
  }
  .mobile-theme-button{
    height: 36px;
  }
  .justify-content-between-mobile{
    justify-content: space-between;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.section-padding {
  padding: 54px 0;
}


.form-group {
  label {
      margin-left: 5px;
      @extend %light-font;
      font-size: (14/$base-font-size)+rem;
  }
  input {
      &.form-control {
        height: 56px;
      }
  }
  textarea {
      resize: none;
  }
  .form-control {
      @extend %placeholder;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.small-font {
  font-size: (12/$base-font-size)+rem;
}
.theme-link-color {
  color: $navigation-menu-link-active-hover-color;
}