@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.search-properties-wrapper {
   &--search-results {
      .search-results {
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         grid-gap: 48px 16px;
         @media (max-width: 639.98px) {
            grid-template-columns: repeat(1, 1fr);
         }
         @media (min-width: 640px) and (max-width: 767.98px) {
            grid-template-columns: repeat(2, 1fr);
         }
         @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
            grid-template-columns: repeat(2, 1fr);
         }
      }
      .properties-card-wrapper {
         max-width: 100%;
         &--image-wrapper {
            img {
               @extend %w-100;
            }
         }
      }
   } 
}