@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";

.rewards-wrapper-popup{
    @extend %position-fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white-color;
    z-index: 1;
    @extend %display-flex;
    @extend %transition;
    @extend %not-visible;
    &.active {
        @extend  %visible;
    }
    @media (max-width: $mobile-devices-max-width+px) {
        padding: 0 10px;
    }
    &--popup{
        margin: auto;
        max-width: 420px;
        @extend %w-100;
        padding: 25px;
        box-shadow: 4px 4px 10px rgba(0,0,0,0.29);
        border: 1px solid $theme-border-color;
        border-radius: 8px;
        @media (max-width: $mobile-devices-max-width+px) {
            padding:15px;
            margin: auto;
            height: 280px;
        }
        img{
            margin: 4px auto;
        }
        .close-popup {
            top: 20px;
            right: 20px;
        } 
    }
}