@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.header-wrapper {
    border-bottom: 1px solid  $theme-border-color;
    padding: 12px 0px;
    .icon-logo{
        h2{
            color:$theme-logo-color;
        }
    }
    @media(max-width: $mobile-devices-max-width+px){
     .header-buttons{
        button{
            margin-right: 0 !important;
        }
     }
    }
}
.navigation-menu-backdrop {
  @extend %position-fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(255, 255, 255 , 0.45);
  &.active {
    visibility: visible;
    opacity: 1;
  }
}