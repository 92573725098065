@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.prperty-type-card-wrapper{
    h3 {
        max-width: 95px;
        margin: auto;
        width: 100%;
        line-height: 1.1;
    }
}