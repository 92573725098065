@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
@import '@assets/scss/common/_mixins.scss';
@import '@assets/scss/common/_normalize.scss';
.review-comments-container{
    .icon{
        margin-top: 24px;
        img {
            height: 36px;
            width: 36px;
        }
    }
    .title{
        h4{
            font-size: (16/$base-font-size)+rem;
        }
        h6{
            color: $theme-font-primary-color;
            font-size: (12/$base-font-size)+rem;
        }
    }
   .comment{
    p {
        font-size: (16/$base-font-size)+rem;
        margin-top: 16px;
        margin-left: 40px;
        padding: 10px 0 24px;
        line-height: 1.2;
        border-bottom: 1px solid $theme-border-color;
    }
}
}


