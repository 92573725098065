@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.customer-story-wrapper{
    padding-top: 46px;
    &--title {
        h2 {
            @media (max-width: $mobile-devices-max-width+px) {
               font-size: (20/$base-font-size)+rem;
            }
        }
    }
    &--cta-wrapper{
        ul{
            li{
                display: inline-flex;
            }
        }
    }
}