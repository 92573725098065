@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
@import '@assets/scss/common/_mixins.scss';

 .community-answer-wrapper{
    border: 1px solid $theme-border-color;
    padding: 25px;
    border-radius: 8px;
   .card-title{
    h4{
        font-size: (22/$base-font-size)+rem;
    }
    a{
        color:$theme-primary-color;
    }
   }
    h5{
        font-size: (16/$base-font-size)+rem;
        color: $theme-secondary-color;
        margin-bottom: 3px;
    }
    @media(max-width:$mobile-devices-max-width+px){
       padding: 10px;
       margin: 0 10px;
    }
    &--counts{
        h1{
            color:$theme-font-primary-color;
        }
    }
 }