@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

%underline-common-css {
    content:'';
    height: 2px;
    position: absolute;
    bottom: 0;
    left:0;
}

.popular-properties-wrapper{
    padding: 84px 0 0;
    @media (max-width: $mobile-devices-max-width+px) {
        padding: 40px 0 0;
    }
    &--title{
        h2 {
            @media (max-width: $mobile-devices-max-width+px) {
                font-size: (20/$base-font-size)+rem;
            }
        }
        a{
            font-size: (22/$base-font-size)+rem;
            color:$theme-font-secondary-color;
            @extend %text-capitalize;
            &:after{
                @extend %underline-common-css;
                background-color: $theme-font-secondary-color;
                width: 100%;
            }
            &:before {
                @extend %underline-common-css;
                width: 0;
                z-index: 1;
                background-color: $theme-secondary-color;
                @include transition(500ms);
            }
            &:hover {
                color:$theme-secondary-color;
                @include transition(500ms);
                &:before {
                    width: 100%;
                }
            }
        }
    }
    &--cta-wrapper{
        ul{
            li{
                display: inline-flex;
            }
        }
    }
}
