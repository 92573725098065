@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/_extends.scss';

.user-profile-page-wrapper {
  &--profile-image {
    max-width: 535px;
    width: 100%;
    height: 145px;
    margin: 0 auto;
    .profile-photo {
        width: 100px;
        height: 100px;
        background-color: $theme-border-color;
        border-radius: 50%;
    }
  }
  &--profile-details {
    padding: 32px;
    border: 1px solid $theme-border-color;
    border-radius: 8px;
    color: $theme-font-secondary-color;
    max-width: 838px;
    margin: 0 auto;
    li {
        border-bottom: 1px solid $theme-border-color;
        padding: 16px 0;
        @extend %display-flex;
        @media (max-width: $small-mobile-devices-width+px) {
            flex-direction: column;
        }
        &:last-child {
            padding-bottom: 0;
            border: none;
        }
        .label {
            @extend %display-inline-block;
            min-width: 120px;
            margin-right: 50px;
        }
    }
  }
}