@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.customer-card-wrapper{
    max-width: 425px;
    width: 100%;
    .customer-details{
       padding: 20px 24px;
       background-color: #F7F7F7;
       p{
           color: $theme-font-primary-color;
       }
       h3{
           line-height: 1.2;
       }
    }
}