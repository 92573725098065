@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
@import '@assets/scss/common/_mixins.scss';

.project-qanda-wrapper {
    &--accordian {
        border: 1px solid $theme-border-color;
        border-radius: 8px;
        padding: 0 16px;
        @extend %position-relative;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        .hidden-input {
            @extend %position-absolute;
            opacity: 0;
            visibility: hidden;
        }
        .hidden-input {
            &:checked {
                &~ div {
                    max-height: 500px;
                    padding: 16px 0;
                }
                &~ label {
                    border-bottom: 1px solid $theme-border-color;
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        &--header {
            padding: 16px 0;
            margin: 0;
            cursor: pointer;
            color: $theme-font-heading-color;
            svg {
                @extend %display-inline-block;
                @extend %transition;
            }
            @media (max-width: $mobile-devices-max-width+px) {
                img {
                    display: none;
                }       
            }
        }
        &--body {
            max-height: 0;
            overflow: hidden;
            @extend %transition;
        }
    }
}