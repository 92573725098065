@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";

.project-summary {
    border: 1px solid $theme-border-color;
    margin: 24px 0;
    padding: 25px;
    border-radius: 8px;
    @media(max-width:$mobile-devices-max-width+px){
        margin: 24px 10px 0;
     }
    .red-color {
        color: #ea4335;
    }
    h2{
        font-size: (28/$base-font-size)+rem;
    }
    h4{
        font-size: (16/$base-font-size)+rem;
    }
    p{
        font-size: (12/$base-font-size)+rem;
    }
    .price-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin-top: 20px;
        @media(max-width:$mobile-devices-max-width+px){
            grid-template-columns: repeat(1, 1fr);
        }
        .project-details {
            border: 1px solid $theme-border-color;
            padding: 20px;
            border-radius: 8px;
            .icon {
                p {
                    font-size: (12 / $base-font-size) + rem;
                    margin-top: 4px;
                }
            }
            .price {
                h4 {
                    font-size: (16 / $base-font-size) + rem;
                }
            }
        }
    }
    .link {
        text-align: center;
        padding-top: 20px;
        a {
            color: $theme-primary-color;
            font-size: (16 / $base-font-size) + rem;
        }
    }
}
