@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";
.callbackrequest-container{
    .input-box {
        width: 100%;
        padding: 16px 24px;
    }
    input{
        border: 1px solid $theme-border-color;
        border-radius: 4px;
        margin-top: 16px;
    }
}