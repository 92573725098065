@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/_extends.scss';

.login-form-wrapper {
    .custom-container{
        @media (max-width: $mobile-devices-max-width+px) {
            align-items: center;
        }
    }
    @media (max-width: $mobile-devices-max-width+px) {
        align-items: center;
    }
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 11;
        background-color: #fff;
        max-height: 100vh;
        overflow-y: auto;
        .form-changes {
            width: 100%;
            margin: 32px 0 20px;
            padding: 16px 35px ;
            border: 1px solid $theme-border-color;
            border-radius: 4px;
        }

        .form-element{
            img{
                position: absolute;
                right: 5px;
                top: 45px;
            }
            .location{
                left:5px;
            }
        }
      
        &--banner-image {
            img{
                max-width: 650px;
            }
            @media (max-width: $mobile-devices-max-width+px) {
                img {
                    width: 100%;
                    margin-bottom: 10px;
                    max-width: 340px;
                    margin: 0 auto;
                }
            }
            @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
                img {
                    width: 100%;
                    margin-bottom: 10px;
                    max-width: 450px;
                }
            }
            @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
                padding: 24px;
            }
        }
    }
    
    
    
