@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";

.project-configuration-wrapper{
    border: 1px solid $theme-border-color;
    padding: 25px;
    border-radius: 8px;
    margin-top: 24px;
    h3{
        font-size: (22/$base-font-size)+rem;
    }
    .card-detils-wrapper{
        border:1px solid $theme-border-color;
        padding: 16px;
        border-radius: 8px;
        margin-top: 22px;
        @media (max-width:$mobile-devices-max-width+px) {
            text-align: center;
        }
        .card-icon {
            @extend %display-flex;
            height: 48px;
            width: 48px;
            background-color:#e3f5ec;
            border-radius: 50%;
            @media (max-width:$mobile-devices-max-width+px) {
                margin:  0 auto;
            }
            p {
                margin:  auto;
                font-size: (12/$base-font-size)+rem;
                color:#05A357;
            }
        }
        .card-details{
            @media (max-width:$mobile-devices-max-width+px) {
                margin:  5px auto;
                text-align: center!important;
            }
            h5{
                font-size: (16/$base-font-size)+rem;
                margin-bottom: 3px;
                @media (max-width:$mobile-devices-max-width+px) {
                    font-size: (14/$base-font-size)+rem;
                }
            }
            p{
                font-size: (12/$base-font-size)+rem;
                @media (max-width:$mobile-devices-max-width+px) {
                    text-align: center!important;
                }
            }
        }
    }
}