
@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
@import '@assets/scss/common/_mixins.scss';
@import '@assets/scss/common/_normalize.scss';
 .property-card-wrapper{
    border: 1px solid $theme-border-color;
    padding: 25px;
    border-radius: 8px;
    @media(max-width:$mobile-devices-max-width+px){
       padding: 10px;
       margin: 0 10px;
    }
    .card-title-name{
        h2{
            font-size: (22/$base-font-size)+rem;
        }
    }
    .card-buttons{
        padding-top: 20px;
        border-top: 1px solid $theme-border-color;
        a{
            color: $theme-primary-color;
        }
        
        .direction-button{
            background-color: transparent;
            border:1px solid $theme-border-color;
            border-radius: 20px;
            margin-right: 10px;
            padding: 8px 16px;
            @media(max-width:$mobile-devices-max-width+px){
                margin-bottom: 20px;
            }
        }
    }
   .card-title{
    h2{
        font-size: (28/$base-font-size)+rem;
        line-height: 16px;
    }
   }
   h4{
        color: $theme-font-primary-color;
        font-size: (16/$base-font-size)+rem;
        
    }
    h5{
        color: $theme-primary-color;
        font-size: (16/$base-font-size)+rem;
    }
    p{
        color: $theme-font-primary-color;
        font-size: (12/$base-font-size)+rem;
        margin-bottom: 10px;
    }
}