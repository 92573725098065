@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
.reward-point-card{
    max-width: 480px;
    width: 100%;
    img{
        margin-bottom: 16px;
        max-width: 420px;
        width: 100%;
    }
    &--details{
        p{
            margin: 3px 0;
            max-width: 400px;
            width: 100%;
        }
        a{
            font-size: (12/$base-font-size)+rem;
            color: $theme-primary-color;
            text-decoration: underline!important;
        }
    }
}