@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/_extends.scss';

.login-form-wrapper {
    .custom-container{
        @media (max-width: $mobile-devices-max-width+px) {
            align-items: center;
        }
    }
    @media (max-width: $mobile-devices-max-width+px) {
        align-items: center;
    }
    &--form {
        padding-top: 40px;
        max-width: 364px;
        width: 100%;
        .theme-button {
            height: 56px;
        }
        .resend-otp-wrapper {
            a {
                top: 50%;
                right: 14px;
                transform: translateY(-50%);
            }
        }
        &--divider {
            padding: 24px 0;
            @extend %position-relative;
            span {
                @extend %display-inline-block;
                background-color: $white-color;
                color:  $theme-border-color;
                position: relative;
                z-index: 1;
                padding: 0 20px;
            }
            &:before {
                content: '';
                @extend %position-absolute;
                height: 1px;
                background-color: $theme-border-color;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 100%;
            }
        }
        &--social-login {
            ul {
                justify-content: space-evenly;
            }
            li {
                a {
                    width: 120px;
                    height: 56px;
                    display: inline-flex;
                    border: 1px solid $theme-border-color;
                    border-radius: $base-border-radius;
                    @extend %transition;
                    &:hover {
                        background-color: $theme-border-color;
                    }
                    img {
                        margin: auto;
                    }
                }
            }
        }
    }
    &--banner-image {
        img{
            max-width: 650px;
        }
        @media (max-width: $mobile-devices-max-width+px) {
            img {
                width: 100%;
                margin-bottom: 10px;
            }
        }
        @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
            padding: 24px;
        }
    }
}