@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";

.property-details-wrapper {
    &--nav {
        ul {
            @media (max-width:  $mobile-devices-max-width+px) {
                max-width: 100%;
                overflow-x: auto;
                display: flex;
            }
            li {
                @extend %display-inline-block;
                padding: 0 30px;
                @media (max-width:  $mobile-devices-max-width+px) {
                    padding: 0 15px 0 0;
                    min-width: 135px;
                    text-align: center;
                }
                a {
                    @extend %display-inline-block;
                    padding: 10px 0px;
                    @media (max-width:  $mobile-devices-max-width+px) {
                        width: 100%;
                    }
                    &.active {
                        color: $theme-primary-color;
                        border-bottom: 2px solid $theme-primary-color;
                    }
                }
            }
        }
    }
    &--image-slider{
        .slide-max-width{
            max-width: 250px;
        }
    }
    &--overview {
        max-width: 1125px;
        width: 100%;
        margin: 24px auto 0;
        .property-overview {
            max-width: 760px;
            width: 100%;
            @media (max-width:$medium-devices-max+px){
                max-width: 100%;
                padding: 0 24px;
            }
            .topics{
                margin: 24px 0;
                h2{
                    font-size: (22/$base-font-size)+rem;
                }
                ul{
                    li{
                        @extend %display-inline-block;
                        margin: 20px 10px 0 0;
                        a{
                            border: 1px solid $theme-border-color;
                            padding: 10px 24px;
                            border-radius: 20px;
                            font-size: (16/$base-font-size)+rem;
                            @extend %display-inline-block;
                            @extend %transition;
                            &.active {
                                color: $navigation-menu-link-active-hover-color;
                                background-color: $navigation-menu-link-active-hover-bg-color;
                            }
                            &:hover {
                                color: $navigation-menu-link-active-hover-color;
                                background-color: $navigation-menu-link-active-hover-bg-color;
                            }
                        }
                    }
                }
            }
        }
        .details-callback-container {
            max-width: 350px;
            width: 100%;
            @media (max-width:$medium-devices-max+px){
                padding: 0 24px;
            }
           .details-callback{
            border: 1px solid $theme-border-color;
            padding: 25px;
            border-radius: 8px;
            margin-bottom: 20px;
           }
            .link {
                text-align: center;
                padding-top: 20px;
                a {
                    color: $theme-primary-color;
                    font-size: (16 / $base-font-size) + rem;
                }
            }
        }
    }
  
}
