@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/_extends.scss';

.inner-page-banner-section-wrapper {
    background-color: $theme-primary-dark-color;
    h1 {
        margin-bottom: 16px;
    }
    h3 {
        max-width: 411px;
    }
}
