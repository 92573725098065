@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';

.progress-bar-wrapper {
    @extend %position-relative;
    max-width: 300px;
    height: 18px;
    border-radius: $base-border-radius;
    background-color: $theme-border-color;
    @media(max-width:$mobile-devices-max-width+px){
        max-width: 150px;
    }
    @extend %w-100;
    &--progress {
        @extend %position-absolute;
        @extend %h-100;
        top: 0;
        left: 0;
        max-width: 100%;
        background-color: #FBBC2C;
        border-radius: $base-border-radius;
    }
}