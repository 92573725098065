@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";
.property-overview-wrapper {
    &--nav {
        ul {
            li {
                @extend %display-inline-block;
                padding: 0 30px;
                a {
                    @extend %display-inline-block;
                    padding: 10px 0px;
                    &.active {
                        color: $theme-primary-color;
                        border-bottom: 2px solid $theme-primary-color;
                    }
                }
            }
        }
    }
    &--image-slider{
        .slide-max-width{
            max-width: 250px;
        }
    }
    &--overview {
        max-width: 1150px;
        width: 100%;
        margin: 0 auto;
        .property-overview {
            max-width: 760px;
            width: 100%;
            margin: 24px 0 0;
            @media(max-width:$medium-devices-max+px){
                max-width: 100%;
                margin: 24px 0px;
            }
        }
        .review-comments {
            max-width: 370px;
            width: 100%;
            .community{
                border: 1px solid $theme-border-color;
                padding: 25px;
                margin: 24px 0 0;
                border-radius: 8px;
                .link {
                    text-align: center;
                    padding-top: 20px;
                    a {
                        color: $theme-primary-color;
                        font-size: (16 / $base-font-size) + rem;
                    }
                }
            }
        }
    }
    &--similar-properties {
        border-top: 1px solid $theme-border-color;
    }
}
