@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
.rewards-wrapper {
    
    &--banner {
        padding: 42px 0;
        @media (max-width:$mobile-devices-max-width+px){
                padding:40px 20px;            
        }
        .banner-header {
            h1 {
                margin: 8px 0;
                font-size: (36 / $base-font-size) + rem;
            }
            p {
                max-width: 620px;
                width: 100%;
                margin: 0 auto;
                line-height: 1.5;
            }
        }
        .banner-points {
            margin: 32px 0 72px;
            @media(max-width: $mobile-devices-max-width+px){
                margin-bottom: 0px;
            }
            ul {
                @media (max-width:$mobile-devices-max-width+px) {
                    display:flex;
                    align-items: center;
                    justify-content: space-evenly;
                }
                li {
                    @extend %display-inline-block;
                    margin-right: 25px;
                    @media (max-width:$mobile-devices-max-width+px) {
                        margin-right: 0;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                    .border-green {
                        border: 2px solid green;
                    }
                    .border-red {
                        border: 2px solid #dd3243;
                    }
                    .border-orange {
                        border: 2px solid orange;
                    }
                    .reward-circle {
                        height: 100px;
                        width: 100px;
                        border-radius: 50%;
                        @media (max-width:$mobile-devices-max-width+px) {
                            height: 70px;
                            width: 70px;
                        }
                        @media (min-width: $small-mobile-devices-width+px) and (max-width: $tablet-devices-min+px){
                            height: 90px;
                            width: 90px;
                        }
                        h6 {
                            margin: auto;
                            font-size: (22 / $base-font-size) + rem;
                        }
                    }
                    p {
                        max-width: 100px;
                        width: 100%;
                        margin-top: 10px;
                        font-size: (14 / $base-font-size) + rem;
                        @media (max-width:$mobile-devices-max-width+px) {
                          max-width: 70px;
                        }
                        @media (min-width: $small-mobile-devices-width+px) and (max-width: $tablet-devices-min+px){
                            max-width: 90px;
                        }
                    }
                }
            }
        }
    }
    &--body {
        .title {
            h2 {
                margin-bottom: 32px;
            }
        }
        .reward-cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 48px 16px;
            @media (max-width: $mobile-devices-max-width+px){
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 48px 0px;
            }
            @media (min-width: $small-mobile-devices-width+px) and (max-width: $tablet-devices-min+px){
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 48px 16px;
            }
        }
    }
    &--footer {
        ul{
            margin-bottom: 16px;
            li{
                @extend %display-inline-block;
                .reward-button {
                    padding: 16px 38px;
                    border: 1px solid $theme-border-color;
                    border-radius: 4px;
                    color: $theme-primary-color;
                    background-color: $white-color;
                }
            }
        }
    }
}
