@import '@/assets/scss/common/_variables.scss';

.get-started-wrapper{
    padding: 80px 0;
    @media(max-width:$mobile-devices-max-width+px){
        padding: 50px 0;;
    }
    &--title{
        h2{
            margin: 16px 0;
        }
    }
    &--details{
        max-width: 800px;
        margin: 0 auto;
        h3{
            line-height: 1.2;
            color: #423F3F;
            margin-bottom: 24px;
        }
    }
}