@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
 
.review-card-wrapper{
    border: 1px solid $theme-border-color;
    padding: 16px 24px;
    border-radius: 8px;
    margin-top: 24px;
    @media(max-width:$mobile-devices-max-width+px){
       padding: 10px;
       margin: 24px 10px 0;
       flex-direction: column;
       align-items: center;
    }
    &--image {
        img {
            max-width: 41px;
        }
    }
    &--details{
        margin-left: 24px;
        @media(max-width:$mobile-devices-max-width+px){
            margin: 16px 0 0;
        }
        .review-card-title{
            .title{
                @media(max-width:$mobile-devices-max-width+px){
                  margin: 0 auto;
                  text-align: center;
                } 
            }
        }
        .review-text{
            p{
                margin-top: 16px;
                font-size: (14/$base-font-size)+rem;
            }
        }
    }
}