@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.footer-wrapper {
   padding: 0px 0 50px;
   background-color: $theme-background-footer-color;
   color: $white-color;
   a {
       color: $white-color;
       @extend %transition;
       &:hover {
           color: $theme-primary-color;
       }
   }
   &--header{
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 40px 0;
    .icon-logo{
        p{
         font-size: (16/$base-font-size)+rem;
        }
        ul{
            li{
                @extend %display-inline-block;
                a{
                 color: $white-color;
                 img{
                     height: 15px;
                     width: 15px;
                     margin: 0px 6px;
                 }
                }
            }
        }
    }
    .subscription-form-wrapper {
      .form-control {
        max-width: 289px;
        min-width: 289px;
        width: 100%;
        background-color: $footer-subscription-form;
        border: none;
        height: 44px;
        color: $white-color;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $white-color;
            font-size: (12/$base-font-size)+rem;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $white-color;
            font-size: (12/$base-font-size)+rem;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $white-color;
            font-size: (12/$base-font-size)+rem;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $white-color;
            font-size: (12/$base-font-size)+rem;
        }
      }
      .submit {
        border: 1px solid rgba(255,255,255,0.55);
        padding: 0px 30px;
        background: none;
        color: $white-color;
        height: 44px;
        line-height: 44px;
        border-radius: $base-border-radius;
        @extend %transition;
        &:hover {
            background-color: $white-color;
            color: $theme-primary-color;
        }
      }
    }
   }
   
    &--body{
        padding: 50px 0 0;
        .company-details{
            .common{
                @media(max-width:$mobile-devices-max-width+px){
                    margin-top: 30px;
                }
                ul{
                    li{
                        a{
                            font-size: (16/$base-font-size)+rem;
                        }
                        @media(max-width:$mobile-devices-max-width+px){
                            font-size: (20/$base-font-size)+rem;
                            width: 100%;
                        }
                        padding-bottom: 15px;
                        @extend %text-capitalize;
                        max-width: 250px;
                    }
                }
            }
            h5{
                color: $white-color;
                @media(max-width:$mobile-devices-max-width+px){
                    font-size: (25/$base-font-size)+rem;
                   
                }
                font-size: (16/$base-font-size)+rem;
            }
            .logo{
                @media(max-width:$mobile-devices-max-width+px){
                    text-align: center;
                    .logo-wrapper{
                        @media(max-width:$mobile-devices-max-width+px){
                            justify-content: center;
                        }
                        
                    }
                }
                p{
                    max-width: 300px;
                    line-height: 1.2;
                    padding: 16px 0;
                    @media (max-width:$mobile-devices-max-width+px){
                        font-size: (18/$base-font-size)+rem;
                        width: 100%;
                        justify-content: center;
                        max-width: 350px;
                        margin: auto;
                    }
                }
            }
        }
    }
    &--footer{
        border-top: 1px solid rgba(255,255,255,0.1);
        padding-top: 24px;
        .footer-links{
            ul{
                @media (max-width:$mobile-devices-max-width+px){
                    margin-bottom: 10px;
                    text-align: center;
                }
                li{
                    @extend %display-inline-block;
                    a{
                        @media (max-width:$mobile-devices-max-width+px){
                            font-size: (20/$base-font-size)+rem;
                        }
                        font-size: (14/$base-font-size)+rem;
                        @extend %text-capitalize;
                    }
                }
                li:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
        p{
            font-size: (16/$base-font-size)+rem;
            @media (max-width:$mobile-devices-max-width+px){
                font-size: (14/$base-font-size)+rem;
            }
        }
    }
   
    @media(max-width: $mobile-devices-max-width+px){
     .footer-buttons{
        button{
            margin-right: 0 !important;
        }
     }
    }
}