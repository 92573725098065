@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.banner-wrapper {
    h1{
        max-width: 1024px;
        margin: 0 auto;
        padding-top: 100px;
        @extend %text-center;
        @media (max-width: $mobile-devices-max-width+px) {
            padding-top: 30px;
            text-align:left;
        }
    }
}