@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.features-card-wrapper {
    @media (max-width: $mobile-devices-max-width+px) {
            margin-bottom: 20px;
    }
    &--image {
        border-radius: $base-border-radius;
        overflow: hidden;
    }
    &--details{
        h3{
            font-size: (28/$base-font-size)+rem;
        }
        a{
            font-size: (16/$base-font-size)+rem;
            border-bottom: 1px solid  $theme-font-primary-color;
            position: relative;
          
            &:hover{
                color:$theme-font-primary-color;
                width: 100%;
            }
        }
    }
}
