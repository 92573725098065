@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/_extends.scss';

.inner-page-wrapper {
    color: $theme-font-primary-color;
    p {
        margin-bottom: 16px;
        @extend %light-font;
        line-height: 1.5;
    }
}