@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.property-search-form-wrapper {
    width: 100%;
    max-width: 1024px;
    margin: 32px auto;
    @media (max-width: $mobile-devices-max-width+px) {
        flex-direction: column;
        button{
            height: 45px;
            margin-top: 20px;
        }
    }
    button{
        height: 56px;
    }
    .img-position{
        top:30%;
        transform: translate(-50%);
        left:20px;
    }
    ::placeholder {
        color: #9D9D9D;
    }
    &> div {
        flex: 1;
        @media (max-width: $mobile-devices-max-width+px) {
            width: 100%;
            margin-left: 0 !important;
            margin-bottom: 8px !important
        }
    }
    .search-button {
        max-width: 132px;
    }
    .form-control {
        height: 56px;
        border: 1px solid $theme-border-color;
        border-radius: $base-border-radius;
        padding: 10px 0 10px 40px;
        &.centered-text {
            padding: 0 24px;
            line-height: 56px;
            color: #9D9D9D;
        }
    }
    &.search-properties-form-wrapper {
        max-width: 100%;
        &> div {
            &:nth-child(1) {
                margin-right: 0.5rem;
            }
        }
        .more-filters-wrapper {
            max-width: 200px;
            &--button {
                margin-top: 0;
                border: 1px solid $theme-border-color;
                border-radius: $base-border-radius;
                background-color: $white-color;
                padding: 16px;
                color: $theme-font-primary-color;
                @extend %transition;
                &:hover {
                    background-color: $theme-primary-color;
                    color: $white-color;
                    svg {
                        fill: $white-color;
                    }
                }
            }
        }
    }
}
.property-dropdown {
    .new-img-position{
        top:30%;
        transform: translate(-50%);
        right:0;
     }
    &--options {
        width: 100%;
        box-shadow: 4px 4px 10px rgba(0,0,0,0.29);
        border-radius: 8px;
        background-color: $white-color;
        top: 0;
        min-width: 360px;
        @media (max-width: $mobile-devices-max-width+px) {
            min-width: 100%;
        }
        @extend %transition;
        @extend %not-visible;
        z-index: 1;
        h6 {
            padding: 16px 24px;
            border-bottom: 1px solid $theme-border-color;
        }
        ul {
            padding: 16px 24px;
        }
        li {
            line-height: 2.5;
            input {
                margin: 0;
                &:checked {
                    &~ span {
                        color: $theme-primary-color;
                    }
                }
            }
        }
        &--min-max-range {
            padding: 16px 24px;
            border-bottom: 1px solid $theme-border-color;
        }
    }
    &:hover {
        .property-dropdown--options {
            @extend %visible;
        }
    }
}