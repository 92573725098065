@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";

.reward-wrapper{
    &--details{
        padding: 20px 0 0   ;
        h4{
            font-size: (22/$base-font-size)+rem;
            max-width: 250px;
            line-height: 1.2;
            margin-bottom: 8px;
        }
        p{
            font-size: (16/$base-font-size)+rem;
            line-height: 1.4;
            margin-bottom: 18px;
        }
        a{
            font-size: (16/$base-font-size)+rem;
            color: $theme-primary-color;
        }
    }
}