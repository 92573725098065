@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
@import '@assets/scss/common/_mixins.scss';

.ask-question-popup-wrapper {
    @extend %position-fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white-color;
    z-index: 1;
    @extend %display-flex;
    @extend %transition;
    @extend %not-visible;
    &.active {
        @extend  %visible;
    }
    &--popup {
        margin: auto;
        max-width: 780px;
        @extend %w-100;
        padding: 30px 44px;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.29);
        border: 1px solid $theme-border-color;
        border-radius: 8px;
        @media (max-width: $mobile-devices-max-width+px) {
            padding: 15px 20px;
        }
        .ask-question-form-element {
            padding: 24px;
            border: 1px solid $theme-border-color;
            border-radius: 8px;
        }
        .close-popup {
            top: 24px;
            right: 24px;
        }
    }
    &--cta-wrapper {
        .theme-button {
            max-width: 145px;
            @extend %text-center;
            @extend %w-100;
            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }
}