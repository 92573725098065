@import '@/assets/scss/common/_variables.scss';

.rebyee-features-wrapper {
    .custom-container {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(3,1fr);
        @media (max-width: $mobile-devices-max-width+px) {
            grid-template-columns: repeat(1,1fr);
        }
    }
}