@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";

.project--description-wrapper{
    border: 1px solid $theme-border-color;
    padding: 25px;
    border-radius: 8px;
    margin-top: 24px;
    h4{
        margin-bottom: 16px;
        font-size: (22/$base-font-size)+rem;
    }
    p{
        border-bottom: 1px solid $theme-border-color;
        padding-bottom: 16px;
        line-height: 1.5;
    }
    &--amenities{
       margin: 10px 0;
        .aminites-icon{
            margin: 4px 8px 0 0px;
        }
        .aminity-list{
            ul{
                li{
                    @media(max-width:$mobile-devices-max-width+px){
                        width: 100%;
                        font-size: (20/$base-font-size)+rem;
                    }
                    width: 50%;
                    float: left;
                    padding-bottom: 8px;
                    color:  $theme-font-primary-color;
                    font-size: (16/$base-font-size)+rem;
                    @extend %light-font;
                    img{
                        margin-right: 16px;
                        @media(max-width:$mobile-devices-max-width+px){
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
}