@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.properties-card-wrapper{
    max-width: 420px;
    width: 100%;
    @extend %display-inline-block;
    &:hover {
        .properties-card-wrapper--image-wrapper {
            img {
                transform: scale(1.1);
            }
        }
    }
    &--image-wrapper {
        @extend %overflow-hidden;
        border-radius: 10px;
        img {
            @include transition(600ms);
        }
    }
    .img-tag{
        top: 0;
        background-color: $theme-secondary-color;
        left: 16px;
        padding: 8px;
        border-radius: 0px 0px 4px 4px;
        p{
            font-size: (13/$base-font-size)+rem;
        }
    }
    .card-details{
        padding: 16px 0;
        h4 {
            font-size: (16/$base-font-size)+rem;
            color: $theme-font-secondary-color;
        }
        h6{
            font-size: (12/$base-font-size)+rem;
            color: $theme-font-primary-color;
            &.red {
                color: $theme-secondary-color;
            }
        }
    }
}

.safari {
    .properties-card-wrapper{
        &:hover {
            .properties-card-wrapper--image-wrapper {
                img {
                    transform: scale(1);
                }
            }
        }
    }
}