@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
 
.review-summary{
    border: 1px solid $theme-border-color;
    padding: 25px;
    border-radius: 8px;
    @media(max-width:$mobile-devices-max-width+px){
       padding: 10px;
       margin: 0 10px;
    }
    .review-title{
        h3{
            font-size: (22/$base-border-radius)+rem;
        }
        a{
            color: $theme-primary-color;
            font-size: (16/$base-border-radius)+rem;
        }
    }
    &--ratings-wrapper {
       flex:2;
       @media(max-width:$mobile-devices-max-width+px){
        width: 100%;
        max-width: 240px;
       }
        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            .ratings-number {
                font-size: (20/$base-font-size)+rem;
                color: $theme-font-secondary-color;
            }
            h3 {
                font-size: (20/$base-font-size)+rem;
                @extend %normal-font;
                color: $theme-font-secondary-color;
                @media(max-width:$mobile-devices-max-width+px){
                    min-width: 35px;
                    text-align: right;
                    margin-left: 7px !important;
                }
            }
        }
    }
    &--points-wrapper{
        
        h2{
            font-size: (72/$base-font-size)+rem;
            color:  $theme-font-primary-color;
        }
        .review-info{
           h6{
            color:  $theme-font-primary-color;
            line-height: 1;
           }
           p{
            font-size: (12/$base-font-size)+rem; 
           }
        }
    }
}