@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";
@import "@assets/scss/common/_mixins.scss";
@import "@assets/scss/common/_normalize.scss";
.project-location{
    border: 1px solid $theme-border-color;
    // margin-top: 24px;
    padding: 25px;
    border-radius: 8px;
    @media(max-width:$mobile-devices-max-width+px){
        margin: 24px 10px;
    }
    .red-color {
        color: $theme-secondary-color;
    }
    p{
        margin-bottom: 16px;
        font-size: (12/$base-font-size)+rem;
    }
    h2{
        font-size: (28/$base-font-size)+rem;
    }
    h4{
        font-size: (16/$base-font-size)+rem;
    }
   
    .near-places-wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        margin-top: 10px;
        @media(max-width:$mobile-devices-max-width+px){
            grid-template-columns: repeat(2, 1fr);
        }
        .location-card{
            border:1px solid $theme-border-color;
            padding: 16px 8px 16px 16px;
            border-radius: 8px;
            .icon{
                p{
                    margin: 0;
                    font-size: (12/$base-font-size)+rem;
                }
            }
        }
    }
}