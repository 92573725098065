@import '@/assets/scss/common/_variables.scss';
@import '@/assets/scss/common/extends.scss';
@import '@/assets/scss/common/mixins.scss';

.filter-properties-popup-wrapper {
    @extend %position-fixed;
    @extend %display-flex;
    top: 0;
    left: 0;
    @extend %w-100;
    width: 100%;
    height: 100vh;
    background-color: $white-color;
    padding: 16px;
    @extend %not-visible;
    &.active {
        @extend %visible;
    }
    .form-wrapper {
        max-width: 870px;
        border: 1px solid $theme-border-color;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.29);
        max-height: 90vh;
        overflow-y: auto;
        @extend %margin-auto;
        &--header {
            padding: 8px 16px;
            border-bottom: 1px solid $theme-border-color;
            &--cta {
                li {
                    @extend %display-inline-block;
                    &:not(:last-child) {
                        margin-right: 16px;
                    }
                }
            }
        }
        &--body-wrapper {
            padding: 8px 16px;
            .filter-wrapper {
                &:not(:last-child) {
                    border-bottom: 1px solid $theme-border-color;
                    margin-bottom: 24px;
                }
                h5 {
                    font-size: 1rem;
                }
                ul {
                    padding-bottom: 24px;
                }
                li {
                    display: inline-block;
                    &:not(:last-child) {
                        margin: 12px;
                    }
                    .filter-radio-button {
                        &:checked {
                            ~label {
                                background-color: $navigation-menu-link-active-hover-bg-color;
                                color: $navigation-menu-link-active-hover-color;
                                border-color: $navigation-menu-link-active-hover-bg-color;
                            }
                        }
                    }
                    label {
                        display: inline-block;
                        padding: 10px 16px;
                        border: 1px solid $theme-border-color;
                        border-radius: 20px;
                        color: $theme-font-primary-color;
                        cursor: pointer;
                        @extend %transition;
                        &:hover {
                            background-color: $navigation-menu-link-active-hover-bg-color;
                            color: $navigation-menu-link-active-hover-color;
                            border-color: $navigation-menu-link-active-hover-bg-color;
                        }
                    }
                    input {
                        display: none;
                    }
                }
            }
        }
    }
}